import {CommonCodeSelectionDialogComponent} from './bo/ui/bo-dialogs/common-code-selection-dialog/common-code-selection-dialog.component';
import {SroCsc1100selectionDialogComponent} from './bo/ui/bo-dialogs/sro/c/sc/sro-csc1100selection-dialog/sro-csc1100selection-dialog.component';
import {ProjectReportPeriodQuartalSelectionDialogComponent} from './bo/ui/bo-dialogs/project-report-period-quartal-selection-dialog/project-report-period-quartal-selection-dialog.component';
import {ProjectStructureElementSelectionDialogComponent} from './bo/ui/bo-dialogs/project-structure-element-selection-dialog/project-structure-element-selection-dialog.component';
import {ProgrammeCostTypeCsc1400selectionDialogComponent} from './bo/ui/bo-dialogs/programme-cost-type/c/sc/programme-cost-type-csc1400selection-dialog/programme-cost-type-csc1400selection-dialog.component';
import {SroDynamicSelectionDialogComponent} from "./bo/ui/bo-dialogs/sro-dynamic-selection-dialog/sro-dynamic-selection-dialog.component";
import {CtpSelectionDialogComponent} from "./bo/ui/bo-dialogs/ctp-selection-dialog/ctp-selection-dialog.component";
import {CtpDynamicSelectionDialogComponent} from "./bo/ui/bo-dialogs/ctp-dynamic-selection-dialog/ctp-dynamic-selection-dialog.component";
import {CustomClassificationDetailCscdynamicDialogComponent} from "./bo/ui/bo-dialogs/classification-detail/c/sc/custom-classification-detail-cscdynamic-dialog/custom-classification-detail-cscdynamic-dialog.component";
import {YfrBudgetBalanceDialogComponent} from "./bo/ui/bo-dialogs/yfr-budget-balance-dialog/yfr-budget-balance-dialog.component";

// NE DIRATI OVO!

export default [
    {
        id: 1,
        component: SroDynamicSelectionDialogComponent,
        ngModelParam: 'sroCode',
        title: 'НИО',
        primaryKey: 'sroId',
        parameters: [
            {
                id: 1,
                targetTable: 'fzn_transaction_detail',
                label: 'fzn_transaction_header.fzn_pjh_id',
                value: 'fthId',
                sqlQuery: 'SELECT * FROM v_sro_dialog_list WHERE fth_id = :fthId ORDER BY pjs_no'
            },
            {
                id: 30,
                targetTable: 'fzn_transaction_detail',
                label: 'fzn_transaction_header.fzn_pjh_id - briefly',
                value: 'fthId',
                sqlQuery: 'SELECT * FROM v_sro_dialog_list WHERE fth_id = :fthId AND sro_id NOT IN (SELECT ftd_sro_dynamic_id FROM fzn_transaction_detail ftd JOIN fzn_transaction_header fth ON fth.fth_id=ftd.ftd_fth_id JOIN programme_document_form pdf ON pdf.pdf_id = ftd.ftd_pdf_id WHERE fth.fth_id= :fthId AND LOWER(pdf_accordion_name) LIKE \'%briefly%\') ORDER BY pjs_no'
},
            {
                id: 31,
                targetTable: 'fzn_transaction_detail',
                label: 'fzn_transaction_header.fzn_pjh_id - FFR_01_DEV_EXPL',
                value: 'fthId',
                sqlQuery: 'SELECT * FROM v_sro_dialog_list WHERE fth_id = :fthId AND sro_id NOT IN (SELECT ftd_sro_dynamic_id FROM fzn_transaction_detail ftd JOIN fzn_transaction_header fth ON fth.fth_id=ftd.ftd_fth_id JOIN programme_document_form pdf ON pdf.pdf_id = ftd.ftd_pdf_id WHERE fth.fth_id= :fthId AND pdf_view_name = \'FFR_01_DEV_EXPL\') ORDER BY pjs_no'
            }
        ]
    },
    {
        id: 2,
        component: ProjectReportPeriodQuartalSelectionDialogComponent,
        ngModelParam: 'prpCode',
        title: 'Период',
        primaryKey: 'prpId',
        parameters: [
            {
                id: 2,
                targetTable: 'fzn_transaction_detail',
                label: 'fzn_transaction_header.fth_pjh_id, prp_type_quarterly',
                value: 'fthPjhId',
                sqlQuery: 'SELECT * FROM project_report_period WHERE prp_pjh_id = :fthPjhId AND prp_type = \'prp_type_quarterly\' order by prp_period_no asc'
            },
            {
                id: 7,
                targetTable: 'fzn_transaction_detail',
                label: 'fzn_transaction_header.fth_pjh_id, prp_type_gantt',
                value: 'fthPjhId',
                sqlQuery: 'SELECT * FROM project_report_period WHERE prp_pjh_id = :fthPjhId AND prp_type = \'prp_type_gantt\' order by prp_period_no asc'
            }
        ]
    },
    {
        id: 3,
        component: ProjectStructureElementSelectionDialogComponent,
        ngModelParam: 'pseCode',
        title: 'Елемент структуре пројекта',
        primaryKey: 'pseId',
        parameters: [
            {
                id: 3,
                targetTable: 'fzn_transaction_detail',
                label: 'fzn_transaction_header.fth_pjh_id, PSE_TYPE_MILESTONE',
                value: 'fthPjhId',
                sqlQuery: 'SELECT * FROM project_structure_element pse1 WHERE pse1.pse_type=\'PSE_TYPE_MILESTONE\' AND pse1.pse_pjh_id = :fthPjhId AND NOT EXISTS (SELECT 1 FROM project_structure_element pse2 WHERE pse2.pse_pse_id = pse1.pse_id) ORDER BY pse_sort DESC'
            },
            {
                id: 4,
                targetTable: 'fzn_transaction_detail',
                label: 'fzn_transaction_header.fth_pjh_id, PSE_TYPE_DELIVERABLE',
                value: 'fthPjhId',
                sqlQuery: 'SELECT * FROM project_structure_element pse1 WHERE pse1.pse_type=\'PSE_TYPE_DELIVERABLE\' AND pse1.pse_pjh_id = :fthPjhId AND NOT EXISTS (SELECT 1 FROM project_structure_element pse2 WHERE pse2.pse_pse_id = pse1.pse_id) ORDER BY pse_sort DESC'
            },
            {
                id: 5,
                targetTable: 'fzn_transaction_detail',
                label: 'fzn_transaction_header.fth_pjh_id, PSE_TYPE_WP',
                value: 'fthPjhId',
                sqlQuery: 'SELECT * FROM project_structure_element pse1 WHERE pse1.pse_type=\'PSE_TYPE_WP\' AND pse1.pse_pjh_id = :fthPjhId AND NOT EXISTS (SELECT 1 FROM project_structure_element pse2 WHERE pse2.pse_pse_id = pse1.pse_id) ORDER BY pse_sort DESC'
            },
            {
                id: 6,
                targetTable: 'fzn_transaction_detail',
                label: 'fzn_transaction_header.fth_pjh_id, PSE_TYPE_SUBACTIVITY',
                value: 'fthPjhId',
                sqlQuery: 'SELECT * FROM project_structure_element pse1 WHERE pse1.pse_type=\'PSE_TYPE_SUBACTIVITY\' AND pse1.pse_pjh_id = :fthPjhId AND NOT EXISTS (SELECT 1 FROM project_structure_element pse2 WHERE pse2.pse_pse_id = pse1.pse_id) ORDER BY pse_sort DESC'
            }
        ]
    },
    {
        id: 4,
        component: ProgrammeCostTypeCsc1400selectionDialogComponent,
        ngModelParam: 'costType.ctpName',
        title: 'Трошак',
        primaryKey: 'pctId',
        parameters: [
            {
                id: 8,
                targetTable: 'fzn_transaction_detail',
                label: 'fzn_transaction_header.fzn_pjh_id',
                value: 'fthId',
                sqlQuery: 'select * from programme_cost_type pct join programme_header ph on pct.pct_prh_id = ph.prh_id join project_header ph2 on ph.prh_id = ph2.pjh_prh_id  join fzn_transaction_header fth  on fth.fth_pjh_id = ph2.pjh_id where fth.fth_id = :fthId'
            },
            {
                id: 9,
                targetTable: 'fzn_transaction_detail',
                label: 'ctp_total = true',
                value: 'fthId',
                sqlQuery: 'select * from programme_cost_type pct join cost_type ctp on ctp.ctp_id = pct.pct_ctp_id where ctp.ctp_total = true'
            }
        ]
    },
    {
        id: 5,
        component: CtpDynamicSelectionDialogComponent,
        ngModelParam: 'ctpName',
        title: 'Трошак (costType)',
        primaryKey: 'ctpId',
        parameters: [
            {
                id: 10,
                targetTable: 'fzn_transaction_detail',
                label: 'ctp_total = true',
                value: 'fthId',
                sqlQuery: 'SELECT * FROM cost_type WHERE ctp_total = true ORDER BY ctp_order'
            }
        ]
    },

    {
        id: 25,
        component: CustomClassificationDetailCscdynamicDialogComponent,
        ngModelParam: 'cldCode',
        title: 'M-rangiranje',
        primaryKey: 'cldId',
        parameters: [
            {
                id: 26,
                targetTable: 'fzn_transaction_detail',
                label: 'cld_clh_id=1003',
                value: 'fthId',
                sqlQuery: 'select * from classification_detail where cld_clh_id=1003  ORDER BY cld_id'
            }
        ]
    },

    {
        id: 27,
        component: YfrBudgetBalanceDialogComponent,
        ngModelParam: 'ftdCostName',
        title: 'Stavke budzeta',
        primaryKey: 'ftdId',
        parameters: [
            {
                id: 28,
                targetTable: 'fzn_transaction_detail',
                label: 'function_yfr_budget_balance_tbl',
                value: 'fthId',
                sqlQuery: 'select * from public.function_yfr_budget_balance_tbl(:fthId, null, null)'
            }
        ]
    }
];
