import {AfterViewInit, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {MessageService} from '../core/message.service';
import {Subscription} from 'rxjs';
import {Message} from '../shared/message';
import {Message as PngMessage} from 'primeng/components/common/api';
import {NavigationEnd, Router} from '@angular/router';
import {timer} from 'rxjs/internal/observable/timer';
import {first} from 'rxjs/operators';

@Component({
  selector: 'app-message',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit, OnDestroy {

  subscription: Subscription;
  subscription1: Subscription;
  timerSubscription: Subscription;

  msgs: PngMessage[] = [];

  fixedToWindow: boolean;

  ngOnInit() {
  }

  constructor(private messageService: MessageService, private router: Router) {
    this.subscription = messageService.messageAnnounced$.subscribe(
      m => {
        if (this.timerSubscription != null) {
          this.timerSubscription.unsubscribe();
        }
        this.clear();
        console.log('showwwwwww msg', m);
        this.showMsgs(m);
        /*this.timerSubscription = Observable.timer(20000).first().subscribe(() => {
          this.clear();
        });*/
        this.timerSubscription = timer(7000).pipe(first()).subscribe(() => {
          this.clear();
        });
      });

    this.subscription1 = router.events.subscribe((val) => {
      console.log('route: ' + val);
      this.clear();

    });

  }

  // ngAfterViewInit() {
  //   const self = this;
  //   window.addEventListener('resize', function() {
  //     if (self.fixedToWindow) {
  //       self.calculateFixedToWindowMessagesWidth();
  //     }
  //   });
  // }

  // calculateFixedToWindowMessagesWidth() {
    // try {
    //   setTimeout(() => {
    //     const layoutMain = document.getElementsByClassName('layout-main')[0];
    //     const width = layoutMain.getBoundingClientRect().width;
    //     const computedStyle = getComputedStyle(layoutMain);
    //     const paddingLeft = parseFloat(computedStyle.paddingLeft);
    //     const paddingRight = parseFloat(computedStyle.paddingRight);
    //     // const marginLeft = parseFloat(computedStyle.marginLeft);
    //     // const marginRight = parseFloat(computedStyle.marginRight);
    //     document.getElementsByClassName('fixed-to-window-messages')[0]['style']['width'] = `${width - paddingLeft - paddingRight}px`;
    //   },100);
    // } catch (e) {
    //   // console.log(e);
    // }
  // }

  showMsgs(messages: Message[]) {
    for (let i = 0; i < messages.length; i++) {
      // console.log('counter: ' + messages[i].severity);

      this.fixedToWindow = !!messages[i]['fixedToWindow'];
      this.msgs.push({severity: messages[i].severity, summary: messages[i].summary, detail: messages[i].detail});
    }

    // if (this.fixedToWindow) {
    //   this.calculateFixedToWindowMessagesWidth();
    // }
    // this.topFunction();

  }

  hide() {
    this.msgs = [];
  }

  clear() {
    this.msgs = [];
  }

  topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
    this.subscription1.unsubscribe();
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

}
